.text-center {
  text-align: center;
}

.working-logo {
  margin: 30px auto;
  position: relative;
  left: calc(50% - 92px);
}

.working-title {
  font-size: xx-large;
}

.working-description {
  font-size: large;
}

.kcontent.modal {
  max-height: 100%;
}

.modal .close-btn {
  text-align: right;
}

.modal .close-btn>svg {
  cursor: pointer;
}

.modal .member_wrap {
  width: auto;
  max-width: 500px;
  margin: 0 auto;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.signup.filebox {
  position: relative;
}

.signup.filebox input[type="file"],
.kcontent.modal .signup.filebox input[type="file"] {
  position: absolute;
  left: 0;
  height: 40px;
  width: 100%;
  opacity: 0;
}

.kcontent.modal .filebox .upload-name {
  max-width: 260px;
  width: auto
}

.top-profile {
  float: left;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  margin-top: 16px;
  background-size: cover;
}

.add_nc_btn {
  width: 180px;
  height: 40px;
  background-color: #e31d1a;
  margin: 0 auto;
  margin-left: 10px;
  float: right;
}

.add_nc_btn a {
  text-align: center;
  color: #fff;
  font-size: 14px;
  display: block;
  line-height: 40px;
}

.add_nc_btn a img {
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -2px;
}

.event-file-upload-logo {
  height: 40px;
  width: 40px;
  float: left;
}

.event-file-upload-logo img {
  height: 40px;
  width: 40px;
}

.event-file-upload-area {
  line-height: 40px;
}

.ql-container.ql-snow>.ql-editor {
  min-height: 200px;
}

.player-file-upload-area {
  position: relative;
  left: 0;
  line-height: 40px;
  width: 40px;
}

.player-file-upload-area input {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  width: 40px;
  opacity: 0;
  display: none;
}

.player-file-upload-area .player-file-uplaod-button {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 40px;
}

.sub_left .player_box {
  width: 100%;
  margin-bottom: 30px;
  display: block;
  overflow: hidden;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
}

.sub_left .player_box>h2 {
  border-bottom: 1px solid #d9d9d9;
  color: #1f1f1f;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
}

.player_list {
  padding: 15px auto
}

.player_list ul li {
  border-bottom: 1px solid #e8e8e8;
  display: block;
  overflow: hidden;
  padding: 20px 0;
}

.player_list .l_img {
  float: left;
  margin-right: 30px;
}

.player_list .l_img img {
  width: 80px;
  height: 80px;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
}

.player_list .r_txt {
  float: left;
  margin-top: 20px;
}

.player_list .r_txt h2 {
  font-size: 18px;
  font-weight: bold;
  color: #1f1f1f;
  margin-bottom: 10px;
}

.player_list .r_txt p {
  color: #aeaeae;
  font-size: 14px;
}

.player_list .tlr {
  width: 110px;
  height: 35px;
  float: right;
  border: 1px solid #000;
  border-radius: 5px;
  margin-top: 20px;
}

.player_list .tlr a {
  display: block;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  color: #1f1f1f;
}

.player_list .tlr a img {
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -2px;
}

.application-info-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.title-cbox {
  border-bottom: 1px solid #d9d9d9;
  color: #1f1f1f;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
}

.player_list.video {
  margin-top: 0;
  margin-bottom: 10px;
}

.player_list.video:after {
  content: "";
  display: block;
  clear: both;
}

.player_list.video:last-child {
  margin-bottom: 0;
}

.player_list.video .tlr {
  margin-top: 10px;
  margin-bottom: 10px
}

.player_list.video .tlr a:first-child {
  border-top: none;
}

.player_list.video .tlr a {
  border-top: 1px solid #000;
  cursor: pointer;
}

.streamContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 1024px;
  min-width: 800px;
}

.streamContainer>div,
.streamContainer iframe {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
}

.disalbe-upload {
  background-color: #eee;
  color: #aeaeae !important;
}

div.can_application_box::before {
  content: "";
  position: relative;
  display: block;
  top: -13px;
  height: 4px;
  background: #e31d1a;
  border-radius: 10px;
  margin: 20px auto;
}

.modal-select-input>div>div {
  z-index: 10000;
  position: relative;
}

.ag-root {
  min-height: 30px;
}

.select-custom-class {
  width: 100%;
}

.role-cotent {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 3px 10px;
  background-color: #ccc;
  color: #000;
  border-radius: 8px;
}

.role-name {
  display: inline-block;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 14px;
  border-right: 1px solid #000;
  padding-right: 9px;
}

.role-name-new {
  display: inline-block;
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 14px;
  color: #666;
}

.role-clear {
  cursor: pointer;
  padding-left: 10px;
  position: relative;
  top: 2px;
}

.player-name {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 10px 5px;
}

.management-filter-contanier {
  margin: 0;
  margin-left: auto;
  margin-bottom: 20px;
}

.management-filter-contanier.two {
  display: inline-block;
  width: 45%;
  margin-bottom: 0;
}

.management-filter-contanier.three {
  display: inline-block;
  width: 30%;
}

.management-filter-contanier.four {
  display: inline-block;
  width: 23%;
}

.management-filter-contanier h2 {
  font-size: x-large;
  display: inline-block;
  margin-right: 20px;
  line-height: 38px;
}

.management-filter-contanier.two h2 {
  font-size: large;
}

.management-filter-contanier.three h2 {
  font-size: large;
}

.management-filter-contanier.four h2 {
  font-size: large;
}

.management-team-selector {
  width: 300px;
  display: inline-block;
  position: relative;
  top: -6px;
}

.management-filter-contanier.two .management-team-selector {
  width: 70%;
  top: -3px;
}

.management-filter-contanier.three .management-team-selector {
  width: 150px;
  top: -3px;
}

.management-filter-contanier.four .management-team-selector {
  width: 140px;
  top: -3px;
}

.team-info-area {
  margin-bottom: 20px;
}

.team-info-text {
  display: inline-block;
  width: 50%;
  font-size: 16px;
  margin-bottom: 8px;
}

.team-info-label {
  display: inline-block;
  font-weight: bold;
  width: 140px;
}

.management-lump-box {
  border-top: 1px solid #999;
  padding-top: 20px;
  text-align: right;
}

.management-lump-box-item {
  display: inline-block;
}

.management-lump-box-item>li {
  width: 440px;
}

.management-lump-box-item>li>h2,
.management-lump-box-item>li>ul,
.management-lump-box-item>li>ul>li {
  display: inline-block;
}

.management-lump-box-item>li>h2 {
  margin-right: 20px;
  line-height: 38px;
  font-weight: bold;
  font-size: 15px;
}

.management-lump-box-item .select-custom-class {
  position: relative;
  width: 200px;
  top: -2px;
  text-align: left;
}

.management-lump-box-item button {
  margin-left: 20px;
  height: 38px;
  padding: 0 20px;
  background-color: #e31d1a;
  color: #FFF;
  border: none;
  cursor: pointer;
}

.ai_box .gUpMat {
  z-index: 0;
}

.con_txt .ql-editor p {
  margin-bottom: 5px;
}

.mpr .period_top h2 {
  border-bottom: 3px solid #e31d1a;
  padding-bottom: 10px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}

/* Tooltip container */

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.schedule-table .ag-cell-value {
  overflow: visible;
}

.create_match {
  margin-left: 20px;
  height: 30px;
  padding: 0 20px;
  background-color: #e31d1a;
  color: #FFF;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
}

.stage_control {
  width: 120px;
  height: 40px;
  background-color: #e31d1a;
  margin: 0 auto;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}

.stage_control.blue {
  background-color: #1a27e3;
}

.stage_control a {
  text-align: center;
  color: #fff;
  font-size: 14px;
  display: block;
  line-height: 40px;
}

.stage_control a img {
  margin-right: 5px;
  vertical-align: middle;
  margin-top: -2px;
}

.custom-ui {
  text-align: center;
  width: 800px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui>h1 {
  margin-top: 0;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 2em;
  color: #fff;
}

.custom-ui>p {
  margin-block-start: 1em;
  margin-block-end: 1em;
  color: #fff;
}

.custom-ui>button {
  width: 180px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.score-content {
  padding: 30px;
}

.score-match-list {
  margin-bottom: 15px;
}

.score-match-box {
  border: 1px;
  padding: 15px 10px;
  background-color: #F0F0F0;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.23);
  cursor: pointer;
}

.score-match-number {
  font-size: 95%;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.score-match-order {
  font-size: 95%;
  font-weight: bold;
  float: right;
  color: #333;
}

.score-match-division {
  font-size: 110%;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 25px;
}

.score-match-team,
.score-match-player {
  width: 50%;
  display: inline-block;
  text-align: center;
}

.score-match-player-vs {
  text-align: center;
  padding: 15px 0;
}

.score-match-box.on {
  background-color: #FFF;
}

.score-match-status {
  font-weight: bold;
  margin-top: 15px;
  text-align: right;
}

.score-content .streamContainer {
  max-width: 100%;
  padding-bottom: 56.25%;
  display: none;
}

.referee-box {
  margin-top: 30px;
  text-align: center;
  width: 25%;
  display: inline-block;
}

.referee-profile-image-wrapper {
  margin: auto;
  margin-bottom: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.referee-profile-image-wrapper.on {
  border: 3px solid #00c400;
}

.referee-profile-image-wrapper.off {
  border: 3px solid #c70000;
}

.referee-profile-image-wrapper img {
  width: 100%;
  height: 100%;
}

.ref-match-info {
  padding: 15px;
  background-color: #FFF;
  border-radius: 12px;
}

.ref-match-order {
  float: right;
  font-size: 110%;
  font-weight: bold;
}

.ref-match-number {
  font-size: 110%;
  font-weight: bold;
}

.ref-match-division {
  font-size: 120%;
  line-height: 1.2;
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.ref-match-team,
.ref-match-player {
  width: 50%;
  display: inline-block;
  text-align: center;
}

.ref-match-player-vs {
  text-align: center;
  padding: 15px 0;
}

.ref-match-status {
  font-weight: bold;
  text-align: right;
  margin-top: 15px;
}

.ref-score-input-meta {
  padding: 0;
  /* background-color: #FFF; */
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ref-score-input-meta .white-box {
  background-color: #FFF;
  background-clip: content-box;
}

.ref-score-input-meta.slider {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  padding: 4px 20px;
}

.ref-score-input-title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.ref-score-input-meta .point-area {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
}

.ref-score-input-meta .left-control,
.ref-score-input-meta .right-control {
  width: 35%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
}

.ref-score-input-meta .point-area {
  font-size: 3em;
  font-weight: bold;
  text-align: center;
}

.ref-score-input-meta .point-area.number {
  width: 50%;
  padding: 0 20px;
}

.ref-score-input-meta .left-control.number {
  width: 30%;
}

.ref-score-input-meta .right-control.number {
  width: 20%;
  padding-right: 20px;
}

.ref-score-number-select .score-title {
  font-size: 18px;
  font-weight: 500;
}

.ref-score-number-select .score-point {
  font-size: 160%;
  font-weight: 700;
  color: #000;
}

.score-content-table-title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  background-color: #c70000;
  color: #FFF;
  padding: 15px 0;
}

.score-number-btn {
  height: 40px;
  width: 100%;
  position: relative;
  left: 0;
  border-radius: 8px !important;
  background-color: #FFF !important;
}

.score-number-btn.selected {
  height: 40px;
  /* border-radius: 8px 0 0 8px !important; */
  background-color: #e31d1a !important;
  color: #FFF;
  position: relative;
  width: 100%;
}

.score-number-btn.selected .MuiButton-label {
  /* padding-right: 5%; */
}

.score-number-btn .MuiButton-label {
  font-size: 26px;
  font-weight: 500;
}

.score-number-btn.selected .MuiButton-label {
  color: #FFF;
  font-weight: 700;
}

.score-number-input-btn {
  width: 100%;
  height: 70px;
  border-radius: 2px !important;
  background-color: #FFF !important;
}

.score-number-input-btn.selected {
  background-color: #e31d1a !important;
}

.score-number-input-btn.selected>span {
  color: #FFF;
}

.score-number-input-btn .MuiButton-label {
  font-size: 30px;
  font-weight: 500;
}

.score-number-input-btn.selected.yellow {
  background-color: #ffee00 !important;
}

.score-number-input-btn.selected.yellow>span {
  color: #000;
}

.score-number-input-btn.MuiButton-contained.Mui-disabled {
  background-color: #c5c5c5 !important;
  box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
}

.score-number-input-btn.MuiButton-contained.Mui-disabled .MuiButton-label {
  color: #333 !important;
}

.ref-score-input-meta .input-range__label {
  font-size: 1.5em;
}

.ref-score-input-meta .input-range__label .input-range__label-container {
  top: -5px;
}

.ref-score-input-meta .input-range__track {
  height: 0.7rem;
  top: 4px;
}

.ref-score-input-meta .max-value {
  cursor: pointer;
  width: 60%;
  height: 100px;
  background-color: #e31d1a;
  margin: 0 auto;
  border-radius: 20px;
  color: #FFF;
  border: none;
  font-size: 2em;
  margin-bottom: 15px;
  display: inline-block;
}

.ref-score-input-meta .min-value {
  cursor: pointer;
  width: 30%;
  height: 80px;
  background-color: #e31d1a;
  margin: 0 4%;
  border-radius: 20px;
  color: #FFF;
  border: none;
  font-size: 2em;
  margin-bottom: 15px;
  display: inline-block;
}

.final-score {
  padding: 15px;
  background-color: #FFF;
  color: #FFF;
  font-size: 16px;
}

.final-score .final-score-title {
  width: 50%;
  font-size: 1.2em;
  font-weight: bold;
  display: inline-block;
}

.final-score .final-score-point {
  width: 50%;
  font-size: 1.5em;
  font-weight: bold;
  display: inline-block;
}

.empty-score {
  margin-top: 40px;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.score-table table {
  width: 100%;
  border-top: 2px solid #6e6e6e;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  word-wrap: break-word;
  word-break: keep-all;
  border-left: none;
  border-bottom: none;
  font-size: 18px;
  background-color: #FFF;
}

.score-table table th,
.score-table table td {
  text-align: center;
  border-right: none;
  vertical-align: middle;
  border-left: 1px solid #eee;
  font-size: 18px;
}

.score-table table th {
  padding: 10px 0;
  border-bottom: 1px solid #bebebe;
  background-color: #e9f6fb;
}

.score-table table td {
  border-bottom: 1px solid #e6e6e6;
}

.score-table table .ref-tag {
  font-size: 19px;
  font-weight: bold;
}

table>thead>tr>th,
table>thead>tr>td,
table>tbody>tr>th,
table>tbody>tr>td,
table>tfoot>tr>th,
table>tfoot>tr>td,
.table>thead>tr>th,
.table>thead>tr>td,
.table>tbody>tr>th,
.table>tbody>tr>td,
.table>tfoot>tr>th,
.table>tfoot>tr>td {
  padding: 12px;
  line-height: 1.71429;
}

.score-content .sub_right .score-page-matches-list {
  padding: 5px;
  background-color: #FFF;
  height: 70vh;
  overflow: auto;
}

.monitor-input {
  font-size: 20px;
  font-weight: bold;
}

.form_ul.monitor input[type="number"] {
  border: 1px solid #eaeaea;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  margin: 0 5px;
}

.form_ul.monitor h2 {
  margin: 5px;
}

.form_ul.monitor h2.monitor-input {
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
}

.form_ul.monitor>li {
  margin-bottom: 15px;
}

.sub-data-input {
  border: 1px solid #eaeaea;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  width: 100%;
}

.twPc-div {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e1e8ed;
  border-radius: 6px;
  height: 225px;
  width: 100%;
}

.twPc-bg {
  background-image: url("https://pbs.twimg.com/profile_banners/50988711/1384539792/600x200");
  background-position: 0 50%;
  background-size: 100% auto;
  border-bottom: 1px solid #e1e8ed;
  border-radius: 4px 4px 0 0;
  height: 95px;
  width: 100%;
}

.twPc-block {
  display: block !important;
}

.twPc-button {
  margin: -81px -10px 0;
  text-align: center;
  width: 100%;
  height: 80px;
}

.twPc-button a {
  color: #FFF;
  font-size: 20px;
}

.twPc-avatarLink {
  background-color: #fff;
  border-radius: 6px;
  display: inline-block !important;
  float: left;
  margin: -30px 10px 0 8px;
  max-width: 100%;
  padding: 1px;
  vertical-align: bottom;
}

.twPc-avatarLink.right {
  float: right;
}

.twPc-avatarImg {
  border: 2px solid #fff;
  border-radius: 7px;
  box-sizing: border-box;
  color: #fff;
  height: 72px;
  width: 72px;
  object-fit: cover;
}

.twPc-divUser {
  margin: 5px 0 20px;
  float: left;
  padding: 5px;
}

.twPc-divUser.right {
  float: right;
  text-align: right;
}

.twPc-divName {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
}

.twPc-divName a {
  color: inherit !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
}

.twPc-divStats {
  margin-left: 11px;
  padding: 15px 0;
}

.twPc-Arrange {
  box-sizing: border-box;
  display: table;
  margin: 0;
  min-width: 100%;
  padding: 0;
  table-layout: auto;
}

ul.twPc-Arrange {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.twPc-ArrangeSizeFit {
  display: table-cell;
  padding: 0;
  vertical-align: top;
  text-align: center;
}

.twPc-ArrangeSizeFit a:hover {
  text-decoration: none;
}

.twPc-StatValue {
  display: block;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.15s ease-in-out 0s;
}

.twPc-StatLabel {
  color: #8899a6;
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0.02em;
  overflow: hidden;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out 0s;
}

.add_nc_btn.ref_match_state {
  position: fixed;
  background-color: #460d0c;
  width: 120px;
  height: 50px;
  line-height: 50px;
  border-radius: 30px;
  z-index: 999;
  bottom: 10px;
  right: 10px;
  opacity: 0.4;
}

.add_nc_btn.ref_match_state a {
  line-height: 50px;
}

.score-type-1-btn {
  height: 100%;
  width: 100%;
  min-height: 110px;
  border-radius: 15px !important;
  background-color: #FFF !important;
}

.score-type-set {
  position: relative;
}

.score-type-set::before {
  position: absolute;
  content: '';
  width: 100%;
  background-color: #FFF;
  right: -30%;
  display: block;
  top: 30%;
  bottom: 30%;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.score-type-1-btn img {
  padding: 10px;
  width: 50px;
}

.score-type-1-btn .MuiButton-label {
  font-size: 40px;
  font-weight: 700;
}

.ref-score-input-circle {
  text-align: center;
  padding-top: 10px;
  width: 130px;
  margin: auto;
}

.send_score.add_nc_btn {
  width: 200px;
  float: inherit;
  margin: 20px auto;
  border-radius: 6px;
}

.send_score.add_nc_btn.off {
  background-color: rgb(174, 174, 174);
}

.react-confirm-alert-overlay {
  overflow: auto;
}

.react-confirm-alert {
  max-height: 95%;
  overflow: auto;
}

.con_box>h2 {
  border-bottom: 1px solid #d9d9d9;
  color: #1f1f1f;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
}

.text-large {
  font-size: 150% !important;
}

.text-strong {
  font-weight: 700;
}

.broadcast-list tr.active td {
  background-color: #61dafb;
}

.lp-copy-sel__option,
.lp-copy-sel__single-value {
  white-space: pre-wrap !important;
  line-height: 1.8;
}

.lp-copy-sel__value-container {
  height: 4em;
}

.right_con>ul>li {
  margin-right: 35px;
}

.right_con ul li a.flag img {
  margin-right: 10px;
  vertical-align: middle;
  margin-top: -3px;
  border-radius: 50%;
  background-color: #FFF;
  border: 1px solid #ececec;
  object-fit: cover;
}

.sub_menu .dropdown-menu li {
  margin-bottom: 15px;
}

.sub_menu .dropdown-menu li .flag img {
  background-color: #FFF;
  border: 1px solid #ececec;
  border-radius: 50%;
  object-fit: cover;
}

.sub_menu .dropdown-menu li:last-child {
  margin-bottom: 0;
}

.top-head {
  background-color: #FFFFFF;
  height: 80px;
  width: 100%;
}

.match-number {
  height: 80px;
  line-height: 70px;
  padding-top: 5px;
  font-size: 30px;
  width: 200px;
  color: #000;
  text-align: center;
}

.match-description {
  height: 80px;
  line-height: 70px;
  padding-top: 10px;
  font-size: 47px;
  font-family: 'Gothic A1', sans-serif;
  width: auto;
  background-color: #5D45FD;
  color: #FFFFFF;
  text-align: center;
}

.match-icon {
  width: 60px;
  padding: 10px;
  background-color: #4232B2;
}

.match-icon img {
  width: 60px;
}

.match-stage-name {
  height: 80px;
  line-height: 70px;
  padding-top: 5px;
  font-size: 30px;
  width: 300px;
  color: #000;
  text-align: center;
}

.team-name-area {
  width: 100%;
  height: 120px;
  margin-top: 30px;
  position: relative;
}

.team-label {
  position: absolute;
  left: calc(50% - 100px);
  width: 200px;
  background-color: #5D45FD;
  color: #FFFFFF;
  height: 40px;
  line-height: 40px;
  font-size: 26px;
  font-family: 'Gothic A1', sans-serif;
  top: -20px;
  text-align: center;
}

.team-label .before {
  position: absolute;
  top: 0;
  left: -20px;
  width: 0;
  height: 0;
  border-bottom: 10px solid #5D45FD;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid #5D45FD;
}

.team-label .after {
  position: absolute;
  top: 0;
  right: -20px;
  width: 0;
  height: 0;
  border-bottom: 10px solid #5D45FD;
  border-top: 10px solid transparent;
  border-left: 10px solid #5D45FD;
  border-right: 10px solid transparent;
}

.team-name {
  width: 100%;
  background-color: #FFF;
  color: #000;
  height: 110px;
  line-height: 110px;
  padding-top: 10px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.player-name-area {
  background-color: #FFFFFF88;
  color: #000;
  height: 500px;
}

.player-name-area div {
  text-align: center;
  font-size: 40px;
  word-break: keep-all;
  padding: 150px 150px;
}

.monitor-content td>div.ref-list-box {
  background-color: #666666;
  height: 120px;
  line-height: 130px;
  font-size: 47px;
}

.monitor-content td>div.ref-list-box:nth-child(2n) {
  background-color: #4D4D4D;
}

.monitor-content td>div.ref-list-box.select {
  background-color: #23CD93;
}

.monitor-content td>div.ref-list-box.select:nth-child(2n) {
  background-color: #1CA476;
}

table.points-table {
  height: 900px;
  margin-top: 55px;
  font-size: 2.5em;
  text-align: center;
}

table.points-table thead {
  height: 120px;
  text-align: center;
  background-color: #EAEAEA;
  border-bottom: 3px solid #FFFFFF;
  color: #000;
}

table.points-table thead .table-team-name {
  background-color: #FFFFFF;
  color: #000;
}

table.points-table thead .table-team-sum {
  background-color: #4232B2;
  color: #FFFFFF;
  width: 200px;
}

table.points-table .ref-point-list {
  border-bottom: 1px solid #FFFFFF;
  font-family: 'Gothic A1', sans-serif;
}

table.points-table .ref-point-list>td {
  background-color: #FFFFFF55;
}

table.points-table .ref-point-list:nth-child(2n)>td {
  background-color: #FFFFFF33;
}

table.points-table .ref-point-list>td:last-child {
  background-color: #5D45FD;
}

table.points-table .ref-point-list:nth-child(2n)>td:last-child {
  background-color: #4232B2;
}


.monitor-content-with-team {
  height: 780px;
}

.total-match-icon {
  width: 80px;
  height: 100px;
  padding: 10px;
  background-color: #4232B2;
}

.total-match-icon img {
  width: 80px;
}

.total-match-description {
  height: 80px;
  line-height: 70px;
  padding-top: 10px;
  font-size: 47px;
  font-family: 'Gothic A1', sans-serif;
  width: 200px;
  background-color: #e6e6e6;
  color: #000;
  text-align: center;
}

.total-match-stage-name {
  height: 80px;
  line-height: 70px;
  padding-top: 10px;
  font-size: 47px;
  font-family: 'Gothic A1', sans-serif;
  width: auto;
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
}

.total-match-stage-point {
  width: 250px;
  height: 80px;
  line-height: 70px;
  padding-top: 10px;
  font-size: 47px;
  font-family: 'Gothic A1', sans-serif;
  color: #FFFFFF;
  text-align: center;
}


.progress-bar {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background:
      radial-gradient(closest-side, black 90%, transparent 90% 100%),
      conic-gradient(#5d45fd 76%, white 0)
}


.ranking-list-info {
  background-color: #FFFFFF55;
}

.ranking-list-info:nth-child(2n) {
  background-color: #FFFFFF33;
}

.ranking-list-info td:last-child {
  background-color: #5D45FD;
}

.ranking-list-info:nth-child(2n) td:last-child {
  background-color: #4232B2;
}